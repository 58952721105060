import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import RouterPage from './Router';
import { useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

const themes = {
  orange: createTheme({
    palette: {
      primary: {
        main: '#FC993D',
      },
      secondary: {
        main: '#FDF6EB',
      },
      tertiary: {
        main: '#f44336',
      },
    },
  }),
  pink: createTheme({
    palette: {
      primary: {
        main: '#B800D8',
      },
      secondary: {
        main: '#ffe8f4',
      },
      tertiary: {
        main: '#f44336',
      },
    },
  }),
  violet: createTheme({
    palette: {
      primary: {
        main: '#640063',
      },
      secondary: {
        main: '#efe5ef',
      },
      tertiary: {
        main: '#f44336',
      },
    },
  }),

  blue: createTheme({
    palette: {
      primary: {
        main: '#2196f3',
      },
      secondary: {
        main: '#e8f4fd',
      },
      tertiary: {
        main: '#f44336',
      },
    },
  }),
};

function App() {
  const [currentTheme, setCurrentTheme] = useState('orange');

  const changeTheme = (theme) => {
    setCurrentTheme(theme);
  };

  return (
    <GoogleOAuthProvider clientId="827114452204-jsra9euv8pb2647bq7tp3ako0ravo4bq.apps.googleusercontent.com">
    <ThemeProvider theme={themes[currentTheme]}>
      <Router>
        <RouterPage changeTheme={changeTheme} />
      </Router>
    </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
